import { useQuery } from "@tanstack/vue-query";

const fetchCurrentUserBrands = async () => {
  console.log("Fetch brands");
  return await fetchWithAuth("/api/currentUser/brands");
};

export const useCurrentUserBrandsQuery = () => {
  return useQuery({
    queryKey: ["brands"],
    queryFn: fetchCurrentUserBrands,
    refetchOnMount: false,
  });
};
